import { render, staticRenderFns } from "./RegisterUserView.vue?vue&type=template&id=563b6cd4&scoped=true&"
import script from "./RegisterUserView.vue?vue&type=script&lang=js&"
export * from "./RegisterUserView.vue?vue&type=script&lang=js&"
import style0 from "./RegisterUserView.vue?vue&type=style&index=0&id=563b6cd4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563b6cd4",
  null
  
)

export default component.exports